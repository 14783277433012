import React, { useState } from "react";
import { GoArrowUp } from "react-icons/go";
import "./ScrollButton.css";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="scroll__button" onClick={scrollToTop}>
      <button>
        <GoArrowUp className="scrollButton__icon" />
      </button>
    </div>
  );
};

export default ScrollButton;
