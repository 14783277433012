import headerimg from "../../src/images/headerimg.png";
import homeimage from "../../src/images/homeimage.webp";
import Företaghome from "../../src/images/Företaghome.webp";
import privatpersonerhome from "../../src/images/privatpersonerhome.webp";
import egenanställdahome from "../../src/images/egenanställdahome.webp";
import cvpbhome from "../../src/images/cvpbhome.webp";
import homeimg1 from "../../src/images/homeimg1.webp";
import pb2home from "../../src/images/pb2home.webp";
import företagimage from "../../src/images/företagimage.webp";
import privatpersonerimg2 from "../../src/images/privatpersonerimg2.webp";
import kreatörerimg from "../../src/images/kreatörerimg.webp";
import aboutus from "../../src/images/aboutus.webp";
import aboutus2 from "../../src/images/aboutus2.webp";
import kontakt from "../../src/images/kontakt.webp";
import testimonial from "../../src/images/testimonial.webp";
import resumeimg2 from "../../src/images/resumeimg2.webp";
import resumeimg3 from "../../src/images/resumeimg3.webp";
import priser from "../../src/images/priser.webp";
import samarbete from "../../src/images/samarbete.webp";
import offert from "../../src/images/offert.webp";
import webbshopimg from "../../src/images/webbshopimg.webp";
import kreatörerimg2 from "../../src/images/kreatörerimg2.webp";
import cvimage from "../../src/images/cvimage.webp";
import cvimage4 from "../../src/images/cvimage4.webp";
import resumeimg4 from "../../src/images/resumeimg4.png";

import Linkedin from "../../src/images/Linkedin.png";
import qicon from "../../src/images/qicon.png";
import qiconone from "../../src/images/qiconone.png";

import rl from "../../src/images/rl.png";
import logo from "../../src/images/logo.png";
import logo2 from "../../src/images/logo2.png";
import rllogo from "../../src/images/rllogo.png";
import cv1 from "../../src/images/cv1.png";
import cv2 from "../../src/images/cv2.png";
import cv3 from "../../src/images/cv3.png";
import pb from "../../src/images/pb.png";
import steg1 from "../../src/images/steg1.png";
import steg2 from "../../src/images/steg2.png";
import steg3 from "../../src/images/steg3.png";
import steg4 from "../../src/images/steg4.png";
import steg5 from "../../src/images/steg5.png";

import ikon1 from "../../src/images/ikon1.png";
import ikon2 from "../../src/images/ikon2.png";
import ikon3 from "../../src/images/ikon3.png";

import checkicon from "../../src/images/checkicon.png";

import cardikon1 from "../../src/images/cardikon1.png";
import cardikon2 from "../../src/images/cardikon2.png";
import cardikon3 from "../../src/images/cardikon3.png";
import cvimg2 from "../../src/images/cvimg2.png";

import fasticon from "../../src/images/fasticon.png";
import psicon from "../../src/images/psicon.png";
import experticon from "../../src/images/experticon.png";
import experienceicon from "../../src/images/experienceicon.png";

import kategori1 from "../../src/images/kategori1.png";
import kategori2 from "../../src/images/kategori2.png";
import kategori3 from "../../src/images/kategori3.png";
import kategori4 from "../../src/images/kategori4.png";
import kategori5 from "../../src/images/kategori5.png";
import telefonikon from "../../src/images/telefonikon.png";
import mailikon from "../../src/images/mailikon.png";
import tiderikon from "../../src/images/tiderikon.png";
import företagstart from "../../src/images/företagstart.png";
import mobile from "../../src/images/mobile.png";

export default {
  logo,
  logo2,
  rllogo,
  egenanställdahome,
  mobile,
  headerimg,
  cvpbhome,
  homeimage,
  kreatörerimg2,
  pb2home,
  aboutus,
  aboutus2,
  kontakt,
  företagimage,
  privatpersonerhome,
  kreatörerimg,

  resumeimg2,
  resumeimg3,
  resumeimg4,
  Linkedin,

  qicon,
  qiconone,
  homeimg1,

  rl,
  cv1,
  cv2,
  cv3,
  pb,
  steg1,
  steg2,
  steg3,
  steg4,
  steg5,
  offert,
  samarbete,

  priser,
  ikon1,
  ikon2,
  ikon3,
  checkicon,
  kreatörerimg,
  privatpersonerimg2,
  cvimage,
  cardikon1,
  cardikon2,
  cardikon3,
  cvimg2,

  Företaghome,
  testimonial,
  fasticon,
  psicon,
  experticon,
  experienceicon,

  cvimage4,
  webbshopimg,

  kategori1,
  kategori2,
  kategori3,
  kategori4,
  kategori5,

  telefonikon,
  mailikon,
  tiderikon,
  företagstart,
};
