import React from "react";
import "./Samarbete.css";

const Samarbete = () => {
  return (
    <div className="samarbete">
      <div className="samarbete__header">
        <div className="samarbete__content">
          <h1 className="samarbete__title animate__animated animate__zoomIn ">
            Samarbeta med oss
          </h1>

          <p className="samarbete__text animate__animated animate__fadeInUp animate__slow	1s ">
            Vi erbjuder administrativa lösningar för företag som vill outsourca
            sin administration. Hos oss får du experthjälp till ett rimligt
            pris.
          </p>
        </div>
      </div>
      <div className="samarbete__container">
        <div className="samarbete-info__Content">
          <h3 className="animate__animated animate__fadeIn">RL Deskservice</h3>
          <h2 className="headingTwo animate__animated animate__fadeIn">
            Samarbeta med oss
          </h2>
          <p className="text animate__animated animate__fadeIn">
            Vad roligt att du vill samarbeta med oss! Detta samarbete passar dig
            som älskar planering, struktur och administration. Vi erbjuder både
            digitala produkter som våra kunder kan ladda ner direkt efter köpet
            men även fysiska produkter som hjälper våra kunder hålla sitt arbete
            strukturerat.
          </p>
          <p className="text">Vi välkomnar både stora och små influencers. </p>
          <h4>Hur går det till?</h4>
          <p className="text">
            Vi erbjuder våra samarbetspartners fina produkter från våra
            kollektioner som kan användas för att skapa content på sociala
            medier. Detta utöver överenskommen kompensation.
          </p>
          <p className="text">
            Om du vill bli en av våra samarbetspartners så får du gärna fylla i
            informationen nedan. När vi har fått in din ansökan så går vi igenom
            den och kikar på dina sociala medieplattformar för att se om din
            sida matchar vårt koncept. Vi hör av oss så snart vi kan och
            informerar dig om vare sig ett samarbete är aktuellt eller inte.{" "}
          </p>
          <p className="text">
            Vill du hellre skicka iväg ett mail till oss kan du göra det till
            e-postadressen collab@rldeskservice.se
          </p>
        </div>
      </div>
      <div className="smForm-container">
        <iframe
          id="JotFormIFrame-231708726826059"
          title="Samarbete"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/231708726826059"
          frameborder="0"
          scrolling="no"
        >
          {" "}
        </iframe>
      </div>
    </div>
  );
};

export default Samarbete;
