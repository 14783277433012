import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./Nyhetsbrev.css";

const Nyhetsbrev = ({ closePopup }) => {
  return (
    <div className="overlay">
      <div className="nyhetsbrev">
        <div className="close-icon">
          <IoMdClose onClick={closePopup} className="close" />
        </div>
        <iframe
          id="JotFormIFrame-231844563453358"
          title="Nyhetsbrev"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/231844563453358"
          frameborder="0"
          scrolling="no"
        >
          {" "}
        </iframe>
      </div>
    </div>
  );
};

export default Nyhetsbrev;
