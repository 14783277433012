import React from "react";
import "./Köpsvilkor.css";

const Köpsvilkor = () => {
  return (
    <div className="cookie__policy">
      <h1 className="policy__title animate__animated animate__zoomIn">
        Köpsvillkor
      </h1>
      <div className="policy__info">
        <h2>
          <span>1. </span> OM RL DESKSERVICE AB
        </h2>
        <p>
          RL Deskservice AB, 559382–1936, är ett konsultbolag som erbjuder
          diverse tjänster inom administration, egenanställning, CV skrivning
          och mycket annat. Företaget har säte i Linköping Sverige, och
          företagets adress är Drottninggatan 43i, 582 27. Observera att detta
          inte är en besöksadress. Önskar du komma i kontakt med oss på RL
          Deskservice AB kan du lättast nå oss via e-post på
          info@rldeskservice.se.
        </p>
      </div>

      <div className="policy__info">
        <h2>
          <span>2. </span> BESTÄLLNING
        </h2>
        <p>
          När du har slutfört din beställning hos oss i vår e-handel skickas en
          orderbekräftelse till din e-postadress. I bekräftelsen finner du alla
          uppgifter som tjänster och produkter, pris, fakturerings – och
          leveransadress samt ordernummer och liknande. Skulle du finna något
          fel i orderbekräftelsen ska du omedelbart kontakta oss via
          e-postadressen ovan eller använda dig av vårt kontaktformulär. Vid köp
          av våra digitala produkter som avtal, CV mallar och andra
          nedladdningsbara varor i vår e-handel får du ett e-post med
          bekräftelse och en länk där din nedladdningsbara produkt finns att
          hämta. Notera att det ibland kan dröja en stund innan mejlet kommer
          fram och det kan ibland hamna i skräpposten.
        </p>
      </div>

      <div className="policy__info">
        <h2>
          <span>3. </span> LEVERANS LEVERANS
        </h2>
        <p>
          Våra leveranstider skiljer sig beroende på vilken tjänst du köper.
          Därför skall du alltid kolla på leveranstiden som står under varje
          specifik tjänst i vår e-handel. Har du några frågor kring
          leveranstider eller liknande så får du kontakta oss via e-post så
          hjälper vi dig gärna. Notera att leveranstiden ibland kan bli längre
          beroende på kundens tillgänglighet, trycket på vår hemsida och
          beroende på säsong. Vi informerar dig som kund vid sådana
          förhållanden. Våra expressleverans tjänster levereras inom 48h efter
          inkommet underlag. Detta innebär att kunden har själv ansvar att
          inkomma med relevant underlag och material som krävs för att vi ska
          kunna fullborda kundens önskemål inom tidsramen som står.
          Expressleverans gäller då utkast av dokumentet och vi på RL
          Deskservice AB kan inte garantera att kunden får den klara varianten
          av dokumentet inom satt tidsram om det föreligger dröjsmål från
          kundens sida. Behandlingstiden på hemsidan är förväntad och vi
          garanterar inte att våra nedladdningsbara produkter kan ske inom
          angiven tid, eftersom oförutsedda förseningar kan uppkomma.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>4. </span> PRISER
        </h2>
        <p>
          Alla våra priser i vår e-handel anges i SEK och alla priser är
          inklusive 25% moms. Vi reserverar oss för prisändringar orsakat av
          prisändring från leverantör, feltryck i prislistan samt felaktigheter
          i priser beroende på felaktig information och förbehåller oss rätten
          att justera priset.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>5.</span> RETURPOLICY/ÅTERBETALNINGSPOLICY
        </h2>
        <p>
          På nedladdningsbara digitala dokument finns ingen ångerrätt eftersom
          de räknas som en tjänst. Vidare finns ingen ångerrätt eller returrätt
          på våra skräddarsydda tjänster heller då dessa anpassas efter kundens
          egna behov. Men vi arbetar konstant för att våra kunder ska vara
          nöjda. Därför erbjuder vi att göra om, justera eller ändra delar av
          tjänsten som kunden finner sig missnöjd kring. Vi anser inte arbetet
          som avslutat förrän kunder bekräftar dess nöje. För mer information om
          vad som gäller hänvisar vi dig till Konsumenttjänstlagen, (SFS
          1985:716). Köp kan dock hävas vid oacceptabelt dålig nedladdning eller
          vid felaktig artikel. Om detta uppstår så ska detta meddelas så snart
          som möjligt genom att kontakta RL Deskservice AB:s kundtjänst. Detta
          gör du enkelt via e-postadressen info@rldeskservice.se. Om den dåliga
          nedladdningen eller felaktiga artikeln orsakats av oss på RL
          Deskservice AB och inte kan lösas eller ersättas på något sätt kan det
          gå att få återbetalning av oss. En felaktig artikel kan vara
          exempelvis vara ett felaktigt eller defekt dokument. Vid eventuell
          tvist följer vi Allmänna reklamationsnämndens rekommendationer.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>6.</span> Kopieringsskydd
        </h2>
        <p>
          Utan att innehållet påverkas och utan kvalitetsförsämring har det
          digitala innehållet försetts med olika typer av kopieringsskydd för
          att förhindra otillåten vidarespridning eller kopiering. All kopiering
          och otillåten vidarespridning är förbjuden.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>7.</span> ANSVARSBEGRÄNSNING{" "}
        </h2>
        <h5 className="sub">7.1 FORCE MAJEURE </h5>
        <p>
          RL Deskservice AB ansvarar inte för indirekta skador som kan uppstå på
          grund av produkten. Vi ansvarar heller inte för förseningar eller fel
          till följd av omständigheter utanför företagets rådande. Dessa
          omständigheter kan exempelvis vara arbetskonflikt, åsknedslag eller
          blixt, eldsvåda, krig, smittspridning eller pandemi, mobilisering
          eller militärinkallelse av större omfattning, rekvisition, beslag,
          myndighetsbestämmelser, myndighetsåtgärd eller underlåtenhet,
          nytillkommen eller ändrad lagstiftning, olyckshändelse och upplopp.
          Vidare ansvarar RL Deskservice AB inte för eventuella förändringar på
          produkter, produktegenskaper, tjänster, eller tjänstegenskaper som
          ändrats av respektive leverantör och andra faktorer utanför vår
          kontroll.
        </p>
        <h5 className="sub">7.2 ÄNDRINGAR </h5>
        <p>
          RL Deskservice AB förbehåller sig rätten att när som helst ändra
          priser för produkter som erbjuds via vår hemsida till dess då köpet är
          fullbordat. RL Deskservice AB erbjuder inget prisskydd eller
          återbetalning vid prissänkning eller ett kampanjerbjudande som sker
          efter ett köp. Vi på RL Deskservice AB tar heller inte ansvar för
          eventuella resultat som uppstår i förhållande till när tjänsten
          används. För alla våra CV, personliga brev och LinkedIn tjänster
          ansvarar inte RL Deskservice AB för resultatet när dessa dokument
          eller tjänster används. Vi ansvarar därför inte för ett negativt
          resultat på en arbetsansökan, eller intervju och heller inte för något
          annat resultat som kan uppkomma. Kunden ansvarar själv för att se till
          så att all information i dess CV, personliga brev och LinkedIn, som
          denne har tillhandahållits från RL Deskservice AB, stämmer överens med
          dennes faktiska information och att all text i dokumenten och på
          dennes LinkedIn är korrekt.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>8.</span> PRODUKTINFORMATION{" "}
        </h2>
        <p>
          Vi reserverar oss för eventuella tryckfel på vår webbplats samt
          slutförsäljning av produkter. Vi garanterar inte att bilderna i vår
          e-handel återger produkternas exakta utseende då en viss färgskillnad
          kan förekomma beroende på bildskärm, fotokvalitet samt upplösning.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>9. </span>INFORMATION OM COOKIES
        </h2>
        <p>
          Enligt lag om elektronisk information ska besökare på en webbplats i
          integritetssyfte få information om att cookies används. Informationen
          i cookien är möjlig att använda för att följa en användares surfande.
          Cookie är en liten textfil som webbplatsen du besöker begär att få
          spara på din dator för att ge tillgång till olika funktioner. Det går
          att ställa in sin webbläsare så att den automatiskt nekar cookies. Mer
          information kan man hitta på Post och telestyrelsens hemsida. Mer
          information om hur vi hanterar cookies finner du i vår Cookiepolicy
          vilket visas genom en knapp längst ner i vänstra hörnet på vår
          hemsida.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>10.</span> DATASKYDDSPOLICY OCH INTEGRITETSPOLICY
        </h2>
        <p>
          Genom att handla hos RL Deskservice AB accepterar du vår
          Integritetspolicy och vår behandling av dina personuppgifter. Vi
          värnar om din personliga integritet och samlar inte in fler uppgifter
          än nödvändigt för att behandla din beställning. Vi säljer eller
          vidareger aldrig dina uppgifter till tredjepart utan rättslig grund.
          RL Deskservice AB är ansvarig för behandlingen av personuppgifter som
          du lämnat till oss som kund. Dina personuppgifter behandlas av oss för
          att kunna hantera din beställning samt i de tillfällen då du har
          önskat nyhetsbrev eller kampanjerbjudanden - för att kunna anpassa
          marknadsföringen åt dina individuella behov. Nedan information är en
          summering av hur vi i enlighet med dataskyddsförordningen (GDPR)
          lagrar och behandlar dina uppgifter. För mer information om hur vi
          behandlar dina personuppgifter finner du vår integritetspolicy på vår
          hemsida.
        </p>
        <h5 className="sub">10.1 Personuppgifter</h5>
        <p>
          En personuppgift är all information som direkt eller indirekt kan
          hänföras till en fysisk person. För att kunna hantera din beställning
          samt svara på frågor relaterat till din order (kundtjänst) lagrar vi
          ditt förnamn- och efternamn, adress, telefonnummer, e-postadress,
          IP-adress och köphistorik. Dina uppgifter lagrar så länge vi har en
          rättslig grund att behandla dina uppgifter, exempelvis för att
          fullfölja avtalet mellan oss eller för att efterleva en rättslig
          förpliktelse enligt exempelvis bokföringslagen eller
          penningtvättslagen.
        </p>
        <h5 className="sub">10.2 Rättslig grund</h5>
        <p>
          I samband med ett köp behandlas dina personuppgifter för att fullfölja
          avtalet med dig. Marknadsföring, kampanjer och liknande utskick sker
          efter samtycke från dig.
        </p>
        <h5 className="sub">10.3 Betalleverantör</h5>
        <p>
          Vid genomförande av köp, delas information med vår betalleverantör.
          Det som lagras är förnamn, efternamn, adress, e-postadress och
          telefonnummer. Väljer du att betala med faktura sparas även
          personnummer hos betalleverantören. Informationen sparas för att kunna
          genomföra köpet och för att skydda parterna mot bedrägeri. De
          betalleverantörer (betaltjänster) som vi använder oss av är: Stripe,
          Swish, kortbetalning och klarna. v
        </p>
        <h5 className="sub">10.4 Nyhetsbrev</h5>
        <p>
          Har du valt att prenumerera på vårt nyhetsbrev delas förnamn,
          efternamn och e-postadress med vår nyhetsbrevsleverantör. Detta för
          att kunna hålla dig uppdaterad med information och erbjudanden i
          marknadsföringssyfte. Vi använder Mailchimp för utskick av nyhetsbrev.
        </p>
        <h5 className="sub">10.5 Dina rättigheter</h5>
        <p>
          Du har rätt att få utdrag av all information som finns om dig hos oss.
          Utdrag levereras elektroniskt i ett läsbart format. Du har rätt att be
          oss uppdatera felaktig information eller komplettera information som
          är bristfällig. Du kan när som helst be att uppgifterna som avser dig
          raderas. Det finns få undantag till rätten till radering, som till
          exempel om det ska behållas för att vi måste uppfylla en rättslig
          förpliktelse (exempelvis enligt bokföringslagen).
        </p>
        <h5 className="sub">10.6 Övrigt</h5>
        <p>
          RL Deskservice AB är ansvarig för lagring och behandling av
          personuppgifter i webbutiken och ser till att reglerna efterföljs. För
          mer information om hur vi behandlar dina personuppgifter vänligen läs
          vår integritetspolicy.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>11.</span> ÄNDRINGAR AV DESSA ANVÄNDARVILLKOR
        </h2>
        <p>
          Vi förbehåller oss rätten att ändra dessa villkor när som helst, så
          var vänlig och kontrollera dem regelbundet. Förändringar och
          förtydliganden träder i kraft omedelbart efter att de publicerats på
          webbplatsen. Om vi gör väsentliga ändringar i den här policyn kommer
          vi att meddela dig att policyn har uppdaterats. Om vår butik förvärvas
          eller slås samman med ett annat företag, kan dina uppgifter överföras
          till de nya ägarna så att vi kan fortsätta att sälja produkter och
          tjänster till dig.
        </p>
      </div>
    </div>
  );
};

export default Köpsvilkor;
