import React from "react";
import { Link } from "react-router-dom";
import { BiCheck } from "react-icons/bi";
import "./ThankYou.css";

const Thankyou = () => {
  return (
    <div className="thankyou">
      <div className="thankyou__content">
        <BiCheck className="success__icon" />
        <h1>Prenumeration bekräftad.</h1>
        <p>
          Tack för att du prenumererar! din prenumeration på vår lista har
          bekräftats. Ett e-mail bör nu vara skickat till din e-mail adress med
          en 15% rabattkod som du kan använda i vår webbshop.
        </p>

        <div className="thankyou__homeBtn">
          <Link to="/" className="link">
            <button type="submit">Fortsätt till Startsidan</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
