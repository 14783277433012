import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Företag from "./components/Företag";
import PrivatPersoner from "./components/PrivatPersoner";
import Webshop from "./components/Webshop";
import Contact from "./components/Contact";
import About from "./components/About";
import Cvpb from "./components/Cvpb";
import IntegritetsPolicy from "./components/IntegritetsPolicy";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import MessageButton from "./components/MessageButton";
import ClipLoader from "react-spinners/ClipLoader";
import ThankYou from "./components/ThankYou";
import useScript from "./webbshop";

import "./App.css";
import NewsLetter from "./components/NewsLetter";
import Egenanställda from "./components/Egenanställda";

import Influencers from "./components/Influencers";
import Offert from "./components/Offert";
import Samarbete from "./components/Samarbete";
import Priser from "./components/Priser";
import Köpsvilkor from "./components/Köpsvilkor";
import Nyhetsbrev from "./components/Nyhetsbrev";
import CategoryProducts from "./components/CategoryProducts";

function App() {
  const [loading, setloading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 1200);
  }, []);

  const closePopup = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setShowModal(false);
    setTimeout(() => {
      setShowModal(true);
    }, 60000);
  }, []);

  return (
    <ScrollToTop>
      {loading ? (
        <div className="loading__spinner">
          <ClipLoader
            className="spinner"
            color={"#b9c1a3"}
            loading={loading}
            size={90}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="App">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/företag"
              element={
                <>
                  <Header />
                  <Företag />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/privat-personer"
              element={
                <>
                  <Header />
                  <PrivatPersoner />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/kreatörer"
              element={
                <>
                  <Header />
                  <Influencers />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/cv-personligtBrev"
              element={
                <>
                  <Header />
                  <Cvpb />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/egenanställda"
              element={
                <>
                  <Header />
                  <Egenanställda />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/webbshop"
              element={
                <>
                  <Header />
                  <Webshop />
                  <Footer />
                </>
              }
            />

            <Route
              path="/kategori"
              element={
                <>
                  <Header />
                  <CategoryProducts />
                  <Footer />
                </>
              }
            />

            <Route
              path="/om-oss"
              element={
                <>
                  <Header />
                  <About />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/Kontakta-oss"
              element={
                <>
                  <Header />
                  <Contact />
                  <Footer />
                </>
              }
            />
            <Route
              path="/integritetspolicy"
              element={
                <>
                  <Header />
                  <IntegritetsPolicy />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/köpsvilkor"
              element={
                <>
                  <Header />
                  <Köpsvilkor />
                  <Footer />
                  <MessageButton />
                </>
              }
            />
            <Route
              path="/prenumerera"
              element={
                <>
                  <Header />
                  <ThankYou />
                  <Footer />
                </>
              }
            />

            <Route
              path="/offert-förfrågan"
              element={
                <>
                  <Header />
                  <Offert />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/samarbeta-med-oss"
              element={
                <>
                  <Header />
                  <Samarbete />
                  <Footer />
                  <MessageButton />
                </>
              }
            />

            <Route
              path="/priser"
              element={
                <>
                  <Header />
                  <Priser />
                  <Footer />
                  <MessageButton />
                </>
              }
            />
          </Routes>
          {showModal ? <Nyhetsbrev closePopup={closePopup} /> : null}
        </div>
      )}
    </ScrollToTop>
  );
}

export default App;
