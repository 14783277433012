import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BsCheck } from "react-icons/bs";
import { BsCheckCircle } from "react-icons/bs";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Företag.css";

const Företag = () => {
  return (
    <section className="company">
      <div className="company__header">
        <div className="company__content">
          <h1 className="company__title animate__animated animate__zoomIn ">
            Administration för <br /> företag
          </h1>

          <p className="company__text animate__animated animate__fadeInUp animate__slow	1s ">
            Vi erbjuder administrativa lösningar för företag som vill outsourca
            sin administration. Hos oss får du experthjälp till ett rimligt
            pris.
          </p>
          <div className="home__btn animate__animated animate__zoomIn animate__slow	2s ">
            <Link to="/offert-förfrågan">
              <button>Begär en offert</button>
            </Link>
          </div>
        </div>
        <div className="image__gradient"></div>
      </div>

      <div className="company__services">
        <div className="company__servicesContent">
          <h3>RL Deskservice</h3>
          <h2 className="headingTwo ">Företagsadministration</h2>
          <p className="text">
            Vårt mål är att agera som ett komplement till företag så att våra
            kunder lätt kan outsourca sina administrativa uppgifter till oss. Vi
            tar oss därför an stora som små ärenden och anpassar våra tjänster
            utifrån kundens behov och önskemål.
          </p>
          <p className="text">
            Går du i tankar om att starta ett företag, eller har du precis
            lanserat? Vi hjälper dig även med allt redan från start!
          </p>

          <div className="companyServices__button">
            <Link to="/kontakta-oss">
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>

        <div className="company__serviceContainer">
          <div className="companyService-img">
            <img src={images.Företaghome} alt="" />
          </div>
        </div>
      </div>

      <div className="company-services">
        <div className="grid-container">
          <h2 className="headingTwo animate__animated animate__fadeInLeft">
            Våra tjänster
          </h2>

          <div className="company-grid">
            <div className="company-grid__item">
              <div className="company-card">
                <div className="card-content">
                  <div className="card-heading">
                    <h2>Löpande administration</h2>
                  </div>
                  <ul className="card-list">
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Fakturering</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Kvittohantering</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Bokföring</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Bokslut (OBS ej årsredovisning)
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Momsredovisning</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Inköp</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Reseplanering och bokning
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Kundsupport gentemot externa kunder
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="company-grid__item large">
              <div className="company-card">
                <div className="card-content">
                  <div className="card-heading">
                    <h2>Löneadministration</h2>
                  </div>

                  <ul className="card-list">
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Skatteinbetalning och arbetsgivaravgifter
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Arbetsgivardeklaration
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Tjänstepension</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Semester, friskvård och andra förmåner{" "}
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Löneutbetalning + lönebesked
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="company-grid__item">
              <div className="company-card">
                <div className="card-content">
                  <div className="card-heading">
                    <h2>Juridik & myndighetskontakt</h2>
                  </div>

                  <ul className="card-list">
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Avtalsskrivning och framtagning av skräddarsydda avtal
                          till företaget
                        </li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">Avtalsgranskning</li>
                      </div>
                    </div>
                    <div className="card-check__list">
                      <div className="checkicon">
                        <BsCheck className="check-ikon" />
                      </div>
                      <div className="about-textBox">
                        <li className="card-list__item">
                          Myndighetsärenden inklusive migrationsfrågor
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="company-sectionTwo">
        <div className="company-textContentTwo">
          <h2 className="headingTwo">Vid uppstart av företag</h2>

          <ul className="company-list">
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Registrera F-skatt</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Registrera bolag hos Bolagsverket</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Setup av bokföringssystem</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Framtagning av policys och avtalsmallar</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Assistera med framtagning av företagsrutiner</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Skapa Google profil</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Skapa hemsida och e-handel (utförs av webbutvecklare)</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Setup marknadsföringsverktyg på hemsida</li>
            </div>
          </ul>
        </div>
        <div className="company-imgBox">
          <img src={images.företagstart} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Företag;
