import React from "react";

const EgenanställdaAnswers = ({ answer }) => {
  return (
    <div>
      <div className="mobile-infoText">
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default EgenanställdaAnswers;
