import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { FiMenu } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import "animate.css/animate.min.css";
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

import { AnimationOnScroll } from "react-animation-on-scroll";
import "./Header.css";

const Header = () => {
  const [show, handleShow] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const [isActive, setIsActive] = useState("1");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        handleShow(true);
      } else handleShow(false);
    });
  }, []);
  return (
    <header>
      <div className={`header-container ${show && "header__color"}`}>
        <div className={`header__logo ${show && "logo__color"}`}>
          <Link
            to="/"
            onClick={(e) => {
              setIsActive("1");
              setToggleMenu(false);
            }}
          >
            <picture>
              <img
                src={images.logo}
                alt=""
                className={`header__logoImg ${show && "header-logoImg__size"}`}
              />
            </picture>
          </Link>
        </div>
        <div className="header__navList">
          <ul className={`header__navLinks ${show && "headerNavlinks__color"}`}>
            <li>
              <Link
                to="/"
                className={isActive === "1" ? "active" : ""}
                id={"1"}
                onClick={(e) => setIsActive(e.target.id)}
              >
                Start
              </Link>
            </li>
            {!toggleDropdown ? (
              <li>
                <Link
                  onMouseEnter={() => setToggleDropdown(true)}
                  className={isActive === "2" ? " drop active" : "drop"}
                  id={"2"}
                  onClick={(e) => setIsActive(e.target.id)}
                >
                  Våra Tjänster
                  <MdExpandMore className="headerDropdown-icon" />
                </Link>
              </li>
            ) : (
              <div className="header__navLinks">
                <li onMouseLeave={() => setToggleDropdown(false)}>
                  <Link
                    className={isActive === "2" ? "active" : ""}
                    id={"2"}
                    onClick={(e) => setIsActive(e.target.id)}
                  >
                    Våra Tjänster
                    <MdExpandLess className="headerDropdown-icon" />
                  </Link>
                </li>
                {toggleDropdown && (
                  <div
                    className="dropdownmenu"
                    onMouseEnter={() => setToggleDropdown(true)}
                    onMouseLeave={() => setToggleDropdown(false)}
                  >
                    <Link
                      to="/företag"
                      className={
                        isActive === "2"
                          ? " dropdown-link active"
                          : "dropdown-link"
                      }
                      id={"2"}
                      onClick={(e) => setIsActive(e.target.id)}
                    >
                      <li className="dropdown-list">Företag</li>
                    </Link>
                    <Link
                      to="/privat-personer"
                      id={"3"}
                      className={
                        isActive === "3"
                          ? "dropdown-link active"
                          : "dropdown-link"
                      }
                      onClick={(e) => setIsActive(e.target.id)}
                    >
                      <li className="dropdown-list">PrivatPersoner</li>
                    </Link>
                    <Link
                      to="/kreatörer"
                      id={"3"}
                      className={
                        isActive === "3"
                          ? "dropdown-link active"
                          : "dropdown-link"
                      }
                      onClick={(e) => setIsActive(e.target.id)}
                    >
                      <li className="dropdown-list">Kreatörer</li>
                    </Link>
                    <Link
                      to="/cv-personligtBrev"
                      id={"4"}
                      className={
                        isActive === "4"
                          ? " dropdown-link active"
                          : "dropdown-link"
                      }
                      onClick={(e) => setIsActive(e.target.id)}
                    >
                      <li className="dropdown-list">CV & Personligt Brev</li>
                    </Link>
                    <Link
                      to="/egenanställda"
                      id={"4"}
                      className={
                        isActive === "4"
                          ? " dropdown-link active"
                          : "dropdown-link"
                      }
                      onClick={(e) => setIsActive(e.target.id)}
                    >
                      <li className="dropdown-list">Egenanställda</li>
                    </Link>

                    <Link
                      to="/priser"
                      id={"4"}
                      className={
                        isActive === "4"
                          ? " dropdown-link active"
                          : "dropdown-link"
                      }
                      onClick={(e) => setIsActive(e.target.id)}
                    >
                      <li className="dropdown-list">Våra priser</li>
                    </Link>
                  </div>
                )}
              </div>
            )}

            <li>
              <a
                href="https://rldeskservice.se/webbshop/butik.html"
                id={"7"}
                className={isActive === "7" ? "active" : ""}
                onClick={(e) => setIsActive(e.target.id)}
              >
                Webbshop
              </a>
            </li>

            <li>
              <Link
                to="/om-oss"
                id={"5"}
                className={isActive === "5" ? "active" : ""}
                onClick={(e) => setIsActive(e.target.id)}
              >
                Om oss
              </Link>
            </li>
            <li>
              <Link
                to="/kontakta-oss"
                id={"6"}
                className={isActive === "6" ? "active" : ""}
                onClick={(e) => setIsActive(e.target.id)}
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </div>

        <div className="mobile__header">
          <div className="mobileMenu">
            {!toggleMenu ? (
              <FiMenu
                className={`menu__icon ${show && "menu__icon__color"}`}
                onClick={() => setToggleMenu(true)}
              />
            ) : (
              <IoMdClose
                className={`menuClose__icon ${show && "menuClose__color"}`}
                onClick={() => setToggleMenu(false)}
              />
            )}

            {toggleMenu && (
              <div className="mobileHeader__list  animate__animated animate__slideInRight ">
                <ul>
                  <li className="mobileHeader__link">
                    <Link
                      onClick={(e) => {
                        setToggleMenu(false);
                        setIsActive(e.target.id);
                      }}
                      to="/"
                      className={isActive === "1" ? "active" : ""}
                      id={"1"}
                    >
                      Start
                    </Link>
                  </li>
                  {!toggleDropdown ? (
                    <li
                      onClick={() => setToggleDropdown(true)}
                      className="mobileHeader__dropdownLink extra"
                    >
                      <Link>Våra Tjänster</Link>
                      <span>
                        <MdExpandMore className="headerDropdown-icon" />
                      </span>
                    </li>
                  ) : (
                    <div className="mobileHeader__navLinks">
                      <li
                        onClick={() => setToggleDropdown(false)}
                        className="mobileHeader__dropdownLink "
                      >
                        <Link
                          className={isActive === "2" ? "active" : ""}
                          id={"2"}
                          onClick={(e) => setIsActive(e.target.id)}
                        >
                          Våra Tjänster
                        </Link>
                        <span>
                          <MdExpandLess className="headerDropdown-icon" />
                        </span>
                      </li>
                      {toggleDropdown && (
                        <div className="mobile-dropdownmenu">
                          <Link
                            to="/företag"
                            className={
                              isActive === "2"
                                ? " mobileDropdown-link active"
                                : "mobileDropdown-link "
                            }
                            id={"2"}
                            onClick={(e) => setIsActive(e.target.id)}
                          >
                            <li
                              onClick={() => setToggleMenu(false)}
                              className="mobileDropdown-list"
                            >
                              - Företag
                            </li>
                          </Link>

                          <Link
                            to="/privat-personer"
                            id={"3"}
                            className={
                              isActive === "3"
                                ? "mobileDropdown-link active"
                                : "mobileDropdown-link"
                            }
                            onClick={(e) => setIsActive(e.target.id)}
                          >
                            <li
                              onClick={() => setToggleMenu(false)}
                              className="mobileDropdown-list"
                            >
                              - Privatpersoner
                            </li>
                          </Link>

                          <Link
                            to="/kreatörer"
                            id={"4"}
                            className={
                              isActive === "4"
                                ? " mobileDropdown-link active"
                                : "mobileDropdown-link"
                            }
                            onClick={(e) => setIsActive(e.target.id)}
                          >
                            <li
                              onClick={() => setToggleMenu(false)}
                              className="mobileDropdown-list"
                            >
                              - Kreatörer
                            </li>
                          </Link>

                          <Link
                            to="/egenanställda"
                            id={"4"}
                            className={
                              isActive === "4"
                                ? " mobileDropdown-link active"
                                : "mobileDropdown-link"
                            }
                            onClick={(e) => setIsActive(e.target.id)}
                          >
                            <li
                              onClick={() => setToggleMenu(false)}
                              className="mobileDropdown-list"
                            >
                              - Egenanställda
                            </li>
                          </Link>

                          <Link
                            to="/cv-personligtBrev"
                            id={"4"}
                            className={
                              isActive === "4"
                                ? " mobileDropdown-link active"
                                : "mobileDropdown-link"
                            }
                            onClick={(e) => setIsActive(e.target.id)}
                          >
                            <li
                              onClick={() => setToggleMenu(false)}
                              className="mobileDropdown-list"
                            >
                              - CV & Personligt Brev
                            </li>
                          </Link>

                          <Link
                            to="/priser"
                            id={"4"}
                            className={
                              isActive === "4"
                                ? " mobileDropdown-link active"
                                : "mobileDropdown-link"
                            }
                            onClick={(e) => setIsActive(e.target.id)}
                          >
                            <li
                              onClick={() => setToggleMenu(false)}
                              className="mobileDropdown-list"
                            >
                              - Våra priser
                            </li>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                  <li className="mobileHeader__link">
                    <a
                      onClick={(e) => {
                        setToggleMenu(false);
                        setIsActive(e.target.id);
                      }}
                      href="https://rldeskservice.se/webbshop/butik.html"
                      className={isActive === "5" ? "active" : ""}
                      id={"5"}
                    >
                      Webbshop
                    </a>
                  </li>

                  <li className="mobileHeader__link">
                    <Link
                      onClick={(e) => {
                        setToggleMenu(false);
                        setIsActive(e.target.id);
                      }}
                      to="/om-oss"
                      className={isActive === "5" ? "active" : ""}
                      id={"5"}
                    >
                      Om oss
                    </Link>
                  </li>

                  <li className="mobileHeader__link ">
                    <Link
                      onClick={(e) => {
                        setToggleMenu(false);
                        setIsActive(e.target.id);
                      }}
                      to="/kontakta-oss"
                      className={isActive === "6" ? "active" : ""}
                      id={"5"}
                    >
                      Kontakta oss
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
