import React from "react";
import { Link } from "react-router-dom";
import "./Offert.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Offert = () => {
  return (
    <div>
      <div className="offert-header">
        <div className="offert-content">
          <h1 className="offert-title animate__animated animate__zoomIn ">
            Få en kostnadsfri offert
          </h1>

          <p className="offert-text animate__animated animate__fadeInUp animate__slow	1s ">
            Fyll gärna i formuläret nedan för en skräddarsydd offert utifrån
            dina behov. Har du några frågor eller funderingar så kan du höra av
            dig till vår kundservice.
          </p>
        </div>
        <div className="image__gradient"></div>
      </div>

      <iframe
        id="JotFormIFrame-231699164518365"
        title="Offert Förfrågan"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/231699164518365"
        frameborder="0"
        scrolling="no"
      >
        {" "}
      </iframe>
    </div>
  );
};

export default Offert;
