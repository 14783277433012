import React, { useState, useEffect } from "react";
import { images } from "../constants";
import { Helmet } from "react-helmet";
import useScript from "../webbshop";
import { BsCheck } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
import { BsTruck } from "react-icons/bs";

const CategoryProducts = () => {
  useScript(
    "https://webshop.one.com/api/v1/cluster/rldeskservice.se/domain/rldeskservice.se/shopfront/init.js"
  );

  return (
    <div>
      <h1>JavaScript Can Change Images</h1>

      <img id="myImage" onclick="changeImage()" src="img/0.jpg" />
      <div className="webshop__container">
        <div className="webshop__product">
          <onecom-webshop-main
            class="onecom-webshop-main"
            params="
            showCategorySelector: true,
            imageRatio: 'landscape',
            cropImages: false,
            productsPerPage: 24,
            productsPerRow: 5,
            userCanSortProducts: true,
            buttonClassname: 'btn-myclass',
            buyButtonText: 'Lägg till i kundvagn',
            embedType : 'html'
          "
          ></onecom-webshop-main>

          <onecom-webshop-cart
            class="onecom-webshop-cart"
            params="
          cartType: 'overlay',
          cartColor: '#424242d0',
          flatBackgroundColor: 'transparent',
          overlayBackgroundColor: '#ffffff',
          showMobileCart: false,
          mobileBackgroundColor: '#ffffff',
          overlayPosition: 0,
      "
          ></onecom-webshop-cart>
        </div>
      </div>
    </div>
  );
};

export default CategoryProducts;
