import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { Helmet } from "react-helmet";
import useScript from "../webbshop";
import { BsCheck } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsTruck } from "react-icons/bs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./Webshop.css";
import Miniheadermobile from "./Miniheadermobile";

const Webshop = () => {
  const [products, setProducts] = useState([]);
  const url = "https://webshop.one.com/api/v2/product";
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        console.log("jsonnnn", json);
        setProducts(json);
      })
      .catch((e) => {
        console.log("e", e);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    lazyLoad: true,

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4.25,
          slidesToScroll: 4,
          infinite: false,

          dots: false,
        },
      },

      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3.25,
          slidesToScroll: 4,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 330,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="webshop" id="webshop">
      <div className="header-bottom">
        <div className="header-bottom__list">
          <ul className="header-bottom__left">
            <span>
              <BsCheck className="check" />
            </span>
            <li>Personlig Service</li>
            <span>
              <BsCheck className="check" />
            </span>
            <li>Kvalitetprodukter</li>
            <span>
              <BsCheck className="check" />
            </span>
            <li>Billiga priser</li>
          </ul>
          <ul className="header-bottom__right">
            <span>
              <MdOutlineMailOutline className="check" />
            </span>
            <li>
              <a className="email-link" href="mailto:Info@rldeskservice.se">
                Info@rldeskservice.se
              </a>
            </li>
            <span>
              <BsTruck className="check" />
            </span>
            <li>Fri frakt vid köp över 599 kr</li>
          </ul>
        </div>
      </div>

      <Miniheadermobile />

      <div className="webshop__header">
        <div className="webshop__content">
          <img src={images.summersale} alt="" />
        </div>
        <div className="webshop-img__mb">
          <img src={images.summersalemobile} alt="" />
        </div>
      </div>
      <h1 className="webshop__title">Våra Produkter</h1>

      <p className="webshop__text">
        Här på vår online butik finner du både fysiska och digitala produkter
        som underlättar ditt <br /> arbete, din vardag och din planering.
      </p>

      <div className="categories">
        <div className="category">
          <a href="https://rldeskservice.se/kategori#!/avtalsmallar">
            <img src={images.kategori1} alt="" />

            <p className="categori-title">Färdiga avtalsmallar</p>
          </a>
        </div>

        <div className="category">
          <a href="https://rldeskservice.se/kategori#!/listor-och-planering">
            <img src={images.kategori4} alt="" />

            <p className="categori-title">
              Anteckningsblock <br /> och planerare
            </p>
          </a>
        </div>

        <div className="category">
          <a href="https://rldeskservice.se/kategori#!/kontorsmaterial">
            <img src={images.kategori3} alt="" />

            <p className="categori-title">Kontorsmaterial</p>
          </a>
        </div>

        <div className="category">
          <a href="https://localhost:3000/kategori#!/flaskor-och-muggar">
            <img src={images.kategori2} alt="" />

            <p className="categori-title">Vattenflaskor och muggar</p>
          </a>
        </div>

        <div className="category">
          <a href="https://rldeskservice.se/kategori#!/digitala-planerare-och-listor">
            <img src={images.kategori5} alt="" />

            <p className="categori-title">Digitala produkter</p>
          </a>
        </div>
      </div>

      <div className="product__slider">
        <Slider {...settings}>
          <div className="category">
            <a href="https://rldeskservice.se/kategori#!/avtalsmallar">
              <img src={images.kategori1} alt="" />

              <p className="categori-title">Färdiga avtalsmallar</p>
            </a>
          </div>

          <div className="category">
            <a href="https://rldeskservice.se/kategori#!/listor-och-planering">
              <img src={images.kategori4} alt="" />

              <p className="categori-title">
                Anteckningsblock <br /> och planerare
              </p>
            </a>
          </div>

          <div className="category">
            <a href="https://rldeskservice.se/kategori#!/kontorsmaterial">
              <img src={images.kategori3} alt="" />

              <p className="categori-title">Kontorsmaterial</p>
            </a>
          </div>

          <div className="category">
            <a href="https://rldeskservice.se/kategori#!/flaskor-och-muggar">
              <img src={images.kategori2} alt="" />

              <p className="categori-title">Vattenflaskor och muggar</p>
            </a>
          </div>

          <div className="category">
            <a href="https://rldeskservice.se/kategori#!/digitala-planerare-och-listor">
              <img src={images.kategori5} alt="" />

              <p className="categori-title">Digitala produkter</p>
            </a>
          </div>
        </Slider>
      </div>

      <div className="webshop__container">
        <div className="webshop__product"></div>
        {products.map((product) => {
          return <div>{product.name}</div>;
        })}
      </div>
    </div>
  );
};

export default Webshop;
