import React from "react";
import "./IntegritetsPolicy.css";

const IntegritetsPolicy = () => {
  return (
    <div className="cookie__policy">
      <h1 className="policy__title animate__animated animate__zoomIn">
        Integritetspolicy
      </h1>
      <div className="policy__info">
        <h2>
          <span>1.</span> Allmänt
        </h2>
        <p>
          RL Deskservice AB, 559382 – 1936, (”Bolaget”) är
          personuppgiftsansvarig i enlighet med GDPR. Vi på RL Deskservice AB
          värdesätter din integritet och dina personuppgifter. Vi tar därför
          EU:s dataskyddsförordning GDPR på fullaste allvar och strävar
          kontinuerligt efter att hålla våra kunders personuppgifter skyddade. I
          vår integritetspolicy avser vi att förklara för dig hur vi samlar in,
          behandlar och skyddar dina personuppgifter.
        </p>
      </div>

      <div className="policy__info">
        <h2>
          <span>2.</span> Insamling av personuppgifter
        </h2>
        <p>
          Generellt sätt insamlar vi på RL Deskservice AB personuppgifter direkt
          från dig. I vissa fall kan insamling av personuppgifter ske från andra
          källor så som tredje parter i form av offentliga källor,
          marknadsföringspartners och sociala medier. Vid användning av våra
          betaltjänster samlas även bankuppgifter och annan relevant information
          som krävs för att genomföra köp. För att använda vissa av våra
          betaltjänster kan vi komma att behöva ta en kreditupplysning på dig.
          Detta kan antingen ske via oss eller någon av våra betalpartners. En
          kreditupplysning kan tas på dig i samband med att du väljer faktura
          som betalningsalternativ.
        </p>
        <p>
          Behandlingen av Personuppgifterna är nödvändig för att vi på RL
          Deskservice AB ska kunna tillhandahålla försäljning och leverans av
          tjänster samt därmed fullgöra köpeavtalet med dig.
        </p>
      </div>

      <div className="policy__info">
        <h2>
          <span>3. </span>
          Vad är personuppgifter och vad menas med behandling av
          personuppgifter?
        </h2>
        <p>
          Personuppgifter är alla uppgifter om en levande och fysisk person som
          direkt eller indirekt kan kopplas till den personen. Detta handlar
          inte bara om namn och personnummer utan det kan även handla om till
          exempel bilder, e-postadresser, bankuppgifter, adress och så vidare.
        </p>
        <p>
          Behandling av personuppgifter är allt som sker med personuppgifterna i
          IT-systemen, oavsett om det handlar om mobila enheter eller datorer.
          Det handlar om till exempel insamling, registrering, strukturering,
          lagring, bearbetning och överföring. I vissa fall kan även manuella
          register omfattas.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>4.</span> Behandling av personuppgifter
        </h2>
        <p>
          RL Deskservice AB kan komma att behandla dina personuppgifter baserat
          på samtycke. Du har alltid rätt att ta tillbaka ditt samtycke efter
          att detta har lämnats.
        </p>
        <div className="policy__info">
          <h3>
            Vi på RL Deskservice AB behandlar personuppgifter i flera syften.
            Dessa syften kan vara:
          </h3>
          <ul>
            <li className="info__list">
              Att kunna hantera kundorder, betalningar och köp
            </li>
            <li className="info__list">Att skapa avtal</li>
            <li className="info__list">Att kunna fullgöra våra tjänster</li>
            <li className="info__list">
              Att ge våra kunder erbjudanden, rabatter och skicka nyhetsbrev
            </li>
            <li className="info__list">Att analysera statistik på vår sida</li>
            <li className="info__list">
              att förbättra och utveckla kvalitet och kundupplevelse för våra
              tjänster
            </li>
            <li className="info__list">Att erbjuda kundsupport</li>
            <li className="info__list">Att förhindra säkerhetshot</li>
            <li className="info__list">
              Att hantera och skicka marknadsföringsmaterial
            </li>
          </ul>

          <p>
            Utöver dessa syften så behandlar vi på RL Deskservice AB även
            personuppgifter på grund av vår skyldighet enligt lag och/eller
            annan författning. Vi är exempelvis skyldiga att spara vissa
            personuppgifter och visst underlag som krävs för bokföring enligt
            Bokföringslagen (1999:1078). Den informationen som vi är skyldig att
            spara skall vara lagrad hos oss på RL Deskservice AB till och med
            det sjunde året efter utgången av det kalenderår då räkenskapsåret
            avslutades. Bokföringsmaterial kan bestå av avtal, e-post eller
            annan korrespondens, fakturor samt annat material som krävs enligt
            relevant lag. Vi har även som skyldighet att lagra dina
            kontaktuppgifter, personuppgifter och betalningshistorik enligt
            penningtvättslagen. Vi kan även vara skyldiga att lagra
            personuppgifter enligt annan svensk eller EU lag eller författning.
          </p>
          <p>
            RL Deskservice AB kan även komma att behandla personuppgifter i
            syfte att analysera och bearbeta statistik med ändamålet att
            förbättra vår verksamhet och kunna erbjuda dig som anställd såväl
            som våra kunder en fortsatt god service och förbättra våra tjänster.
          </p>
        </div>
      </div>
      <div className="policy__info">
        <h2>
          <span>5. </span>Egenanställd
        </h2>
        <p>
          Egenanställda på RL Deskservice AB har en egen integritetspolicy som
          kompletterar denna för att reglera behandling av personuppgifter på
          bästa sätt. Denna kan ni läsa här.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>6. </span>Cookies på vår webbsida{" "}
        </h2>
        <p>
          Vi använder Cookies på vår webbsida för att kunna erbjuda en bättre
          användarvänlighet för våra besökare. Cookies och liknande tekniker är
          mycket små textdokument eller kodkoder som ofta innehåller en unik
          identifikationskod. När du besöker en webbplats eller använder en
          mobil applikation ber ett system din dator eller mobilenhet om
          tillstånd att spara den här filen på din dator eller mobilenhet och få
          tillgång till informationen. Information som samlas in via Cookies och
          liknande tekniker kan innehålla datum och tid för besöket och hur du
          använder en viss webbplats eller mobilapplikation.
        </p>
        <p>
          Besöker du endast vår öppna webbsida kan vi inte se några
          personuppgifter i våra Cookies utan dessa erbjuder bara statistik och
          anonym information. RL Deskservice AB kan komma att använda olika
          statistiska tjänster så som Google Ads, Google Analytics och Meta med
          syfte att skapa målgrupper på annonsplattformarna.
        </p>
        <p>
          Cookies kan även se till att du under ditt besök på vår webbsida och
          vid användning av vår Webbshop förblir inloggad, att alla föremål
          förblir lagrade i din kundvagn, att du kan handla säkert och
          webbplatsen fortsätter att fungera smidigt. Beroende på dina
          preferenser kan dessutom våra egna Cookies användas för att ge dig
          riktade annonser som matchar dina personliga intressen. För att läsa
          mer om vår Cookie Policy gå in på cookies inställningar genom att
          klicka på cookies ikonen längst ner till vänster på skärmen sen
          klickar du på inställningar.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>7. </span>Undersökningar{" "}
        </h2>
        <p>
          RL Deskservice AB kan komma att behandla personuppgifter i samband med
          genomförande av undersökningar och enkäter i syfte om att utveckla vår
          verksamhet och förbättra din upplevelse. Vid sådana undersökningar
          meddelas du om behandling av personuppgifter och i vilket syfte den
          informationen skall användas.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>8. </span>Marknadsföring{" "}
        </h2>
        <p>
          RL Deskservice AB kan komma att kontakta dig i samband med direkt
          marknadsföring vilket är ett berättigat intresse då vi ska
          marknadsföra nya eller befintliga tjänster. Detta kan ske i form av
          e-post marknadsföring, prenumerationstjänster, reklam via post eller
          PR utskick (vid förfrågan). Skulle du inte vilja ta del av sådan
          marknadsföring kan du antingen meddela RL Deskservice AB via e-post
          eller klicka ”avsluta prenumeration” i e-postutskicken du mottar.
          Syftet med vår marknadsföring kan vara att erbjuda dig rabatterade
          priser på våra tjänster, dela nyheter om nya tjänster och produkter
          eller dela annan information med dig.
        </p>
        <p>
          I samband med att du integrerar med RL Deskservice AB genom vår
          hemsida, laddar ner innehåll, köper våra tjänster och liknande så
          kommer vi på RL Deskservice AB att behandla dina personuppgifter
          utifrån de berättigade intressen som nämns ovan.{" "}
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>9. </span>
          Vilka personuppgifter behandlar vi för att kunna hjälpa dig med ditt
          jobbsökande?
        </h2>
        <p>
          Den informationen som RL Deskservice AB behöver behandla för att kunna
          assistera i CV ärenden, personliga brev och assistera i granskning och
          setup av LinkedIn profiler:{" "}
        </p>
        <ul>
          <li className="info__list">För och efternamn,</li>
          <li className="info__list">
            Kontaktinformation som Telefonnummer och E-postadress{" "}
          </li>
          <li className="info__list">Hemadress</li>
          <li className="info__list">Personnummer (i vissa fall)</li>
          <li className="info__list">Yrkestitel, arbetsbeskrivning</li>
          <li className="info__list">CV och personligt brev</li>
          <li className="info__list">Information om dina tidigare arbeten </li>
          <li className="info__list">Lösenord (för LinkedIn tjänster)</li>
        </ul>
      </div>
      <div className="policy__info">
        <h2>
          <span>10. </span>Lösenord
        </h2>
        <p>
          När du lämnar information om Lösenord eller liknande känslig
          information lagras den information endast till och med att ärendet är
          klart. När ärendet är avslutat så raderas alla spår av den
          informationen. Vi rekommenderar att du, i syfte att öka säkerheten för
          ditt konto, använder ett tillfälligt lösenord under ärendets gång och
          att ni byter lösenord omedelbart efter avslutat ärende.
        </p>
        <p>
          Vi på RL Deskservice AB ansvarar inte för eventuella direkta eller
          indirekta intrång, cyberattacker eller säkerhetshot som kan uppstå mot
          ert konto i samband med våra tjänster om inte det finns ett tydligt
          orsakssamband där intrånget, attacken eller säkerhetshotet uppstod på
          grund av en direkt koppling till vår användning av ert konto och att
          intrånget, attacken eller säkerhetshotet uppstod under tiden då vi
          hade tillgång till ert konto. Uppstår intrånget, attacken eller hotet
          i efterhand ansvarar kunden själv för detta.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>11. </span>Lämna information till extern part
        </h2>
        <p>
          RL Deskservice AB kan komma att dela dina personuppgifter med
          myndigheter som polismyndigheten som begär tillgång till
          personuppgifter från RL Deskservice. I dessa fall lämnas informationen
          över endast om det finns ett domslut eller myndighetsbeslut som
          beviljar detta och det är tvingande enligt svensk eller EU lag.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>12. </span>Hur länge sparar och behandlar vi dina
          personuppgifter?
        </h2>
        <p>
          RL Deskservice AB sparar och behandlar dina uppgifter utifrån behov.
          Vi lagrar bokföringsinformation i sju års tid i enlighet med lag. Mer
          om detta finner du under punkt 3. Kundens CV eller annan personlig
          information som inte är nödvändig att lagra, raderas automatiskt efter
          30 dagar från avslutat uppdrag. Kontaktuppgifter, personuppgifter och
          betaluppgifter kan komma att lagras i 5 år efter avslutat uppdrag i
          enlighet med Penningtvättslagen samt övrig svensk eller EU lag.
        </p>
        <p>
          Personuppgifter som inkommer till kundsupport i samband med
          kundserviceärenden raderas senast efter ett år som huvudregel, men kan
          lagras i fem år efter att de har inkommit, om det så krävs. Skulle
          eventuell svensk lagstiftning kräva att informationen lagras längre än
          så, då är det tillämplig lag som gäller.{" "}
        </p>
        <p>
          Du har möjlighet att själv begära att dina personuppgifter raderas. Om
          sådan begäran inkommer till RL Deskservice AB skall personuppgifterna
          raderas senast en månad efter inkommen begäran. Detta sker under
          förutsättningen att vi inte har en rättslig förpliktelse att spara
          informationen. Om en rättslig förpliktelse föreligger informerar vi
          dig om detta skriftligen inom 1 månad efter inkommen begäran.{" "}
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>13. </span>Hur håller vi dina personuppgifter skyddade?
        </h2>
        <p>
          RL Deskservice AB vidtar de bästa åtgärderna för att öka skyddet för
          dina personuppgifter. Dessa åtgärder kan vara:
        </p>
        <ul>
          <li className="info__list">Lagring på säkra platser</li>
          <li className="info__list">Kryptering av information</li>
          <li className="info__list">IT – och Säkerhetspolicys</li>
          <li className="info__list">IT – och säkerhetsrutiner</li>
          <li className="info__list">
            Begränsning av tillgång till personuppgifter inom bolaget
          </li>
        </ul>
        <p>
          Vi på RL Deskservice AB låter inte obehöriga ha tillgång till
          information som denne inte behöver för att kunna utföra sitt arbete.
          RL Deskservice AB har även sekretessförbindelser med sina interna
          anställda såväl som sina egenanställda. Skulle du vilja ha vidare
          information om våra processer eller begära ut registerutdrag på den
          informationen vi har om dig, kan du vända dig till oss direkt via
          e-post.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>14. </span>Dina rättigheter
        </h2>
        <p>
          Du har rätt att få tillgång till dina personuppgifter som vi har
          lagrat på dig genom att skicka in en begäran till oss på RL
          Deskservice AB. Detta gör du enkelt genom att skicka ett e-post till
          adressen{" "}
          <a href="mailto:Info@rldeskservice.se">info@rldeskservice.se.</a>
        </p>
        <p>
          Du har även som nämnt i ovanstående punkter rätt att få dina uppgifter
          raderade genom att skicka in en begäran. Läs punkt 5 för mer
          information.
        </p>
      </div>
      <div className="policy__info">
        <h2>
          <span>15.</span> Kontaktuppgifter till RL Deskservice AB
        </h2>
        <p>
          {" "}
          <span>Organisationsnummer :</span> 559382 – 1936
        </p>
        <p>
          <span>Adress :</span> Drottninggatan 43i, 582 27 Linköping (observera
          att detta inte är en besöksadress)
        </p>
        <p>
          <span>Telefonnummer : </span> 076 – 005 77 60
        </p>
        <p>
          <span>E-postadress : </span>
          <a href="mailto:Info@rldeskservice.se"> info@rldeskservice.se</a>
        </p>
      </div>
    </div>
  );
};

export default IntegritetsPolicy;
