import React from "react";
import { BsCheck } from "react-icons/bs";
import { BsTruck } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Miniheadermobile.css";

const Miniheadermobile = () => {
  const settingsTwo = {
    dots: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    preventMovementUntilSwipeScrollTolerance: true,
    swipeScrollTolerance: 50,

    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="header-bottom__mobile">
      <Slider {...settingsTwo}>
        <div className="header-bottom__container">
          <ul className="header-bottom__mobile">
            <span>
              <BsCheck className="check-icon" />
            </span>
            <li>Personlig Service</li>
            <span>
              <BsCheck className="check-icon" />
            </span>
            <li>Kvalitetprodukter</li>
            <span>
              <BsCheck className="check-icon" />
            </span>
            <li>Billiga priser</li>
          </ul>
        </div>
        <div className="header-bottom__container">
          <ul className="header-bottom__mobile">
            <span>
              <BsTruck className="check-icon" />
            </span>
            <li>Fri frakt vid köp över 599 kr</li>
          </ul>
        </div>
      </Slider>
    </div>
  );
};

export default Miniheadermobile;
