import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { BsCheck } from "react-icons/bs";
import "./PrivatPersoner.css";

const Influencer = () => {
  return (
    <section className="privatPersoner">
      <div className="privatPersoner__header">
        <div className="privatPersoner__content">
          <h1 className="privatPersoner__title animate__animated animate__zoomIn ">
            Administration för <br /> privatpersoner
          </h1>

          <p className="privatPersoner__text animate__animated animate__fadeInUp animate__slow	1s">
            Vi hjälper dig som privatperson med all form av administration du
            behöver. Vi gör det enkelt för alla att få hjälp.
          </p>
        </div>
        <div className="image__gradient"></div>
      </div>
      <div className="company__services">
        <div className="company__servicesContent">
          <h3>Administration</h3>
          <h2 className="headingTwo">För privatpersoner</h2>
          <p className="text">
            Vi erbjuder administrativ assistans inom olika områden. Nedan finner
            du några av våra administrativa tjänster som riktar sig mot dig som
            privatperson. Vi skräddarsyr våra tjänster utifrån våra kunders
            behov och har du ett ärende som du behöver hjälp med, kan kontakta
            gärna oss för ett kostnadsförslag. Skulle du ha flera ärenden som du
            vill ha hjälp med, kan vi skapa ett paketerbjudande till dig, vilket
            blir billigare för dig som kund.
          </p>

          <div className="companyServices__button">
            <Link to="/kontakta-oss">
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>

        <div className="company__serviceContainer">
          <div className="companyService-img">
            <img src={images.privatpersonerimg2} alt="" />
          </div>
        </div>
      </div>

      <div className="company-sectionTwo">
        <div className="pb-imgBox">
          <img src={images.privatpersonerhome} alt="" />
        </div>
        <div className="company-textContentTwo">
          <h2 className="headingTwo animate__animated animate__zoomIn">
            Våra tjänster till privatpersoner
          </h2>
          <ul className="company-list">
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Hjälp med att betala fakturor</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Inköp </li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Resebokning och planering</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Avtalsskrivning och avtalsgranskning</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Avtalsmallar</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>
                Administration för hemtransportering till utlandet vid dödsfall
              </li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Migrationsärenden inkl. visum ansökan</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Rådgivning</li>
            </div>
            <div className="check-list">
              <span>
                <BsCheck />
              </span>
              <li>Övrig administrativ assistans</li>
            </div>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Influencer;
