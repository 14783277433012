import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { BsCheck } from "react-icons/bs";
import "./Priser.css";

const Priser = () => {
  return (
    <div className="pricing">
      <div className="pricing__header">
        <div className="pricing__content">
          <h1 className="pricingHeader__title animate__animated animate__zoomIn ">
            Våra priser
          </h1>
          <p className="pricingHeader__text animate__animated animate__fadeInUp animate__slow	1s">
            Nedan finner du en översikt över vår prislista. Om du är i behov av
            våra företagstjänster, eller lite större tjänster inom
            privatadministration eller administration för influencers och vill
            ha en offert kan du höra av dig till oss direkt genom knappen nedan.
          </p>
          <div className="home__btn animate__animated animate__zoomIn animate__slow	2s ">
            <Link to="/kontakta-oss" key={"6"} id={"6"}>
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>
        <div className="image__gradient"></div>
      </div>

      <h2 className="headingTwo">Prislista</h2>
      <p className="pricing__text">
        Få ett fullt CV och personligt brev skräddarsytt efter dina önskemål och
        förväntningar!. Det ingår alltid service i 30 dagar efter avslutat köp.
        Skulle du vilja justera, tillägga eller ta bort något från dokumenten så
        hjälper jag dig med det!
      </p>

      <div className="pricing__container">
        <div className="product-list__spc">
          <div className="product__content">
            <div className="card-title__spc">
              <h3>Priser för CV & linkedin tjänster</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__listTwo">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skräddarsytt CV och personligt brev = 899kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skräddarsytt CV och personligt brev (expressleverans) = 1
                      299 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skräddarsytt CV = 599 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skräddarsytt personligt brev = 599 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      LinkedIn setup (svenska) = 399 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      LinkedIn setup (svenska & engelska) = 499 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Justering av CV = fr. 149 kr
                    </li>
                  </div>
                </div>

                <div className="card-text">
                  <p>
                    Standardleverans på utkast: 3 - 5 arbetsdagar efter inkommet
                    material. OBS! förseningar kan tillkomma beroende på kunden
                    eller hur högt tryck vi har på våra tjänster.
                  </p>
                  <p>
                    Expressleverans på utkast: 48h efter inkommet material. OBS!
                    förseningar kan tillkomma beroende på kunden. Tjänster med
                    expressleverans har alltid prioritering.
                  </p>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="product__list">
          <div className="product__content">
            <div className="card-title">
              <h3>
                Administration för <br /> företag
              </h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__list">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skapa Google profil = 499 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Setup bokföringssystem = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Momsredovisning = 499 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Löneadministration = 499 kr/h
                    </li>
                  </div>
                </div>

                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Fakturering och bokföring = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Mail korrespondens och kundsupport gentemot kundens
                      externa klienter = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Migrationsärenden inkl. ansökan om
                      arbetsuppehållstillstånd = fr. 4 999 kr
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="product-list__spc">
          <div className="product__content">
            <div className="card-title__spc">
              <h3>Administration för privatpersoner</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__listTwo">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Hjälp med att betala fakturor = 29 kr/faktura
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Hjälp med att betala fakturor (prenumeration) = fr. 99
                      kr/mån
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Privatadministration = 399 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Migrationsärenden = fr. 2 999 kr/ärende (beroende på
                      ärendet)
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Administration för hemtransportering till utlandet vid
                      dödsfall = fr. 4 999 kr
                    </li>
                  </div>
                </div>

                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Avtalsskrivning = fr. 199 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Avtalsgranskning = 199 kr/dokument
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="product__list">
          <div className="product__content">
            <div className="card-title">
              <h3>Influencers, kreatörer och frilansare</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__list">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skriva och skicka offert på kundens vägnar = 99 kr/per
                      offert
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Mötesbokning och schemaläggning = 199 kr/h
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Mail korrespondens och kundsupport gentemot kundens
                      externa klienter = 199 kr/h. Alt. 49 kr/mail
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skapa presentationsmaterial, pitch mail osv. = 199 kr/st
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Assistans med att utforma mail svar, skapa standard mail
                      osv. = 49 kr/st
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Skräddarsytt mediakit = 249 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Resebokning = 29 kr/resa och resenär
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Avtalsgranskning = 199 kr/dokument
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Avtalsskrivning = fr. 199 kr
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">Rådgivning = 299 kr/h</li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon__two" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Övrig administrativ assistans = 399 kr/h
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>

        <div className="product-list__spc">
          <div className="product__content">
            <div className="card-title__spc">
              <h3>Nystartspaket 4 999 kr</h3>
            </div>
            <div className="product__info">
              <ul className="pricing-card__listTwo">
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">Registrera för F-skatt</li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Registrera på Bolagsverket
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">Setup Google profil</li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">Setup Bokföringssystem</li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Setup marknadsföringsverktyg och Google Analytics
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">
                      Assistans med att öppna företagskonto
                    </li>
                  </div>
                </div>
                <div className="card-check__list">
                  <div className="checkicon">
                    <BsCheck className="check-icon" />
                  </div>
                  <div className="about-textBox">
                    <li className="card-list__item">2h rådgivning</li>
                  </div>
                </div>
                <div className="card-text">
                  <p>
                    Kontakta gärna oss och berätta om dina behov så sätter vi
                    ihop en offert till dig där vi inkluderar alla priser som
                    tillgodoser dina önskemål.
                  </p>
                  <p>
                    Observera att priserna på sidan kan komma att ändras och vi
                    reserverar oss för eventuella feltryck i prislistan och
                    förbehåller oss rätten att justera priserna. Alla våra
                    priser i vår e-handel anges i SEK och alla priser är
                    inklusive 25% moms.
                  </p>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Priser;
